import {common} from './common'

export const environment = {
  production: true,
  ...common,
  iFrameURL: "http://localhost:3000/",  // change this URL to live react-chat-module URL.
  // S3 bucket https://simba-insurance-images.s3.us-west-2.amazonaws.com/
  firebase: {
    apiKey: "AIzaSyBOleUJLj18gbm_1jjqiz4cxB5XvYk8opY",
    authDomain: "yoloh-659c4.firebaseapp.com",
    projectId: "yoloh-659c4",
    storageBucket: "yoloh-659c4.appspot.com",
    messagingSenderId: "4723946493",
    appId: "1:4723946493:web:78b9ae1afac2828dd75f94",
    measurementId: "G-5TVY2R3XEG",
    databaseURL: 'https://vision-api-1fda5-default-rtdb.firebaseio.com',
    locationId: 'us-central'
  },
  firebaseUsersCollection: "sit_users",
  cognito: {
    identityPoolId:'us-west-2:7d87daea-e0e3-496a-aca6-8999dae9808b',
    userPoolId: 'us-west-2_ixGKUtKiM',
    userPoolWebClientId: '43gc0c9l4rleou2tg0c5ojqc3o', //=> agentClientId, we had to use webClientId as per the library
  },
  aws: {
    projectRegion: "us-east-1",
    appSyncGraphQLEndpoint: "https://glieq7uq3be3jh3wnrjcwabn6u.appsync-api.us-east-1.amazonaws.com/graphql",
    service: 'execute-api',
    region: 'us-west-2'
  },
  api_endpoint: "https://deswgri7dd.execute-api.us-west-2.amazonaws.com/sit/web",
}

